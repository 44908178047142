.work {
  .project-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    .project-title{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all .6s ease;
      h4 {
        color: $text-white;
        padding: 10px 16px;
        background-color: $primary-black;
        letter-spacing: .09em;
        text-shadow: 2px 2px 2px $primary-black;
      }
    }
    &:hover .project-title {
      opacity: 1;
    }
    .project-thumb {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }
    &:hover .project-thumb {
      transform: scale(1.1);
      filter: grayscale(75%);
      opacity: 0.6;
    }
  }
}