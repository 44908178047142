iframe {
	border: 0 !important;
}
figure {
	margin: 0;
}

.post-content {
	position: relative;
	z-index: 10;
}

.standard-image-box {
	position: relative;
	height: 0;
	.standard-image {
		position: absolute;
		width: calc(100% + 40px);
		left: -20px;
	}
	.caption {
		position: relative;
		font-size: .9em;
		color: lighten($text-primary, 20%);
	}
}