@import '../hamburger/hamburgers';

.app-logo {
  .serif {
    display: inline-block;
    width: auto;
    //margin: 0;
  }
}
.app-nav-trigger {
  float: right;
  outline: none;
  transform: translateY(16px);
  @include MQ(md) {
    transform: translateY(25px);
  }
  @include MQ(lg) {
    transform: translateY(34px);
  }
  @include clearfix;
}

@include MQ(lg) {
  .app-main-header {
    width: 300px;
    position: fixed;
    left: 20px;
  }
}