// breakpoints

$S:     576px;
$M:     768px;
$L:     1024px;
$XL:    1200px;

// media queries

@mixin MQ($canvas) {
  @if $canvas == sm {
    @media only screen and (min-width: $S) { @content; }
  }
  @else if $canvas == md {
    @media only screen and (min-width: $M) { @content; }
  }
  @else if $canvas == lg {
    @media only screen and (min-width: $L) { @content; }
  }
  @else if $canvas == xl {
    @media only screen and (min-width: $XL) { @content; }
  }
}