.blog-box {
	max-width: 1200px;
	position: relative;
	opacity: 0;
	transition: all 1s 0s;

	&.in-view {
		opacity: 1;
	}

	.blog-image-box {
		position: relative;
		overflow: hidden;

		.post-read-more {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: all .6s ease;

			h3 {
				color: $text-white;
				padding: 10px 16px;
				background-color: $primary-black;
				letter-spacing: .07em;
				text-shadow: 2px 2px 2px $primary-black;
				@include MQ(md) {
					letter-spacing: .09em;
				}
			}
		}

		.post-thumb {
			width: 100%;
			height: 100%;
			opacity: 1;
			transition: all 0.4s ease-in-out;
		}
	}

	&:hover .post-read-more {
		opacity: 1;
	}
	&:hover .post-thumb {
		transform: scale(1.1);
		filter: grayscale(75%);
		opacity: 0.6;
	}

	.blog-content-box {
		.post-title {
			margin: 5px 0;
		}
	}

	margin-bottom: 25px;
}